<template>
    <div class="content">
        <el-table :data="newsList" style="width: 100%; flex: 1" height="1%" size="medium" header-cell-class-name="table-header-cell"
                  :header-cell-style="{fontWeight: 'normal', color: '#444', background: '#F5F7FA',height:'50px',fontSize: '16px'}"
                  :cell-style="{fontSize: '14px',color: '#666',height: '76px'}">
            <el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
            <el-table-column prop="title" label="通知标题" align="center"></el-table-column>
            <el-table-column prop="sort" label="文章排序" align="center"></el-table-column>
            <el-table-column prop="addtime" label="发布时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="220">
                <template slot-scope="scope">
                    <el-button size="small" type="primary" plain @click="viewNews(scope.row)">查看</el-button>
                    <el-button size="small" type="primary" @click="editNews(scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="delNews(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="newsPages.currentPageNum"
                :page-size="newsPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="newsPages.total"
                @current-change="newsCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {news, newsDel} from '../../utils/apis'

    export default {
        name: "News",
        data() {
            return {
                //新闻列表
                newsList:[],
                //分页
                newsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total:0
                }
            }
        },
        methods: {
            //获取新闻列表
            getNewsList() {
                let params = {
                    type: 3,
                    paging: 1,
                    page: this.newsPages.currentPageNum,
                    pageSize: this.newsPages.eachPageNum,
                }
                news(params).then((res) => {
                    this.newsList = res.data.list;
                    this.newsPages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //查看
            viewNews(row) {
                let routeUrl = this.$router.resolve({
                    path: '/state/index',
                    query: {
                        news_id: row.news_id
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            //编辑
            editNews(row) {
                this.$router.push({
                    path: '/stateManage/AddNews',
                    query: {
                        id: row.news_id
                    }
                })
            },
            //删除
            delNews(row) {
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let formData = new FormData();
                    formData.append('news_id', row.news_id);
                    newsDel(formData).then((res) => {
                        this.$message.success(res.msg);
                        this.getNewsList();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //分页
            newsCurrentChange(val) {
                this.newsPages.currentPageNum = val;
                this.getNewsList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .content {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 1%;
    }
</style>