<template>
    <div class="carousel-content">
        <div class="carousel-title">
            <span class="title"
                  v-for="item in manageTab"
                  :class="{current: currentTab === item.id}"
                  :key="item.id"
                  @click="changeStateTab(item.id)">
                {{item.name}}
            </span>
        </div>
        <div class="btn-carousel">
            <el-button size="medium" class="btn-green add-btn" v-if="currentTab === '1'" @click="addState(currentTab)">新增通知</el-button>
            <el-button size="medium" class="btn-green add-btn" v-if="currentTab === '2'" @click="addState(currentTab)">新增新闻</el-button>
        </div>
        <Notice ref="notice" v-if="currentTab === '1'"/>
        <News ref="news" v-if="currentTab === '2'"/>
    </div>
</template>

<script>
    import Notice from '../../../components/state/Notice';
    import News from '../../../components/state/News';
    export default {
        name: "stateManageIndex",
        data() {
            return {
                //切换tab
                manageTab: [
                    {
                        id: '1',
                        name: '通知管理'
                    },
                    {
                        id: '2',
                        name: '新闻管理'
                    }
                ],
                currentTab: '1'
            }
        },
        components: {
            Notice,
            News
        },
        created() {
            setTimeout(() => {
                this.$refs.notice.getNoticeList();
            }, 10);
            // if (this.currentTab === '1') {
            //     console.log('111',this.currentTab)
            //     setTimeout(() => {
            //         this.$refs.notice.getNoticeList();
            //     }, 10);
            // }
            if (this.$route.query.type) {
                if (Number(this.$route.query.type) === 1) {
                    this.currentTab = '1';
                    setTimeout(() => {
                        this.$refs.notice.getNoticeList();
                    }, 10);
                }
                if (Number(this.$route.query.type) === 2) {
                    this.currentTab = '2';
                    setTimeout(() => {
                        this.$refs.news.getNewsList();
                    }, 10);
                }
            }
        },
        methods:{
            //切换
            changeStateTab(id) {
                this.currentTab = id;
                if (id === '1') {
                    setTimeout(() => {
                        this.$refs.notice.getNoticeList();
                    }, 10);
                }
                if (id === '2') {
                    setTimeout(() => {
                        this.$refs.news.getNewsList();
                    }, 10);
                }
            },
            //新增
            addState(type) {
                if (type === '1') {
                    this.$router.push({
                        path: '/stateManage/AddNotice'
                    })
                }
                if (type === '2') {
                    this.$router.push({
                        path: '/stateManage/AddNews'
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .carousel-content{
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 100%;
        padding:0 20px;
        box-sizing: border-box;
        .carousel-title{
            height: 74px;
            line-height: 74px;
            border-bottom: 1px solid #dcdcdc;
            box-sizing: border-box;
            font-size: 16px;
            color: #222;
            .title {
                position: relative;
                display: inline-block;
                height: 100%;
                line-height: 84px;
                margin-right: 48px;
                cursor: pointer;
                &:hover {
                    &:before {
                        position: absolute;
                        content: '';
                        top: 70px;
                        width: 64px;
                        height: 4px;
                        background: #20C2C9;
                    }
                }
            }
            .current {
                &:before {
                    position: absolute;
                    content: '';
                    top: 70px;
                    width: 64px;
                    height: 4px;
                    background: #20C2C9;
                }
            }
        }
        .btn-carousel{
            display: flex;
            justify-content: flex-end;
            padding: 10px 0;
            box-sizing: border-box;
            .add-btn {
                padding: 10px 16px;
            }
        }
        .cover-info{
            display: inline-block;
            width: 273px;
            height:66px;
        }
        .logo-info{
            display: inline-block;
            width: 42px;
            height: 42px;
        }
        img{
            max-width: 100%;
            max-height: 100%;
        }
        ::v-deep .el-table .cell{
            padding-right: 0;
        }
        ::v-deep .el-table .cell,::v-deep .el-table--border td:first-child .cell, ::v-deep  .el-table--border th:first-child .cell {
            padding-left:0;
        }
    }
</style>