<template>
    <div class="content">
        <el-table :data="noticeList" style="width: 100%; flex: 1" height="1%" size="medium" header-cell-class-name="table-header-cell"
                  :header-cell-style="{fontWeight: 'normal', color: '#444', background: '#F5F7FA',height:'50px',fontSize: '16px'}"
                  :cell-style="{fontSize: '14px',color: '#666',height: '76px'}">
            <el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
            <el-table-column prop="title" label="通知标题" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.status === 0">进行中</span>
                    <span v-if="scope.row.status === 1">结束</span>
                </template>
            </el-table-column>
            <el-table-column prop="sort" label="文章排序" align="center"></el-table-column>
            <el-table-column prop="addtime" label="发布时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="220">
                <template slot-scope="scope">
                    <el-button size="small" type="primary" plain @click="viewNotice(scope.row)">查看</el-button>
                    <el-button size="small" type="primary" @click="editNotice(scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="delNotice(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="noticePages.currentPageNum"
                :page-size="noticePages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="noticePages.total"
                @current-change="noticeCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {notice, noticeDel} from '../../utils/apis'

    export default {
        name: "Notice",
        data() {
            return {
                //通知列表
                noticeList:[],
                //分页
                noticePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total:0
                }
            }
        },
        created() {
            // console.log('type',type)
        },
        methods: {
            //获取通知列表
            getNoticeList() {
                let params = {
                    type: 3,
                    paging: 1,
                    page: this.noticePages.currentPageNum,
                    pageSize: this.noticePages.eachPageNum,
                }
                notice(params).then((res) => {
                    this.noticeList = res.data.list;
                    this.noticePages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //查看
            viewNotice(row) {
                let routeUrl = this.$router.resolve({
                    path: '/state/index',
                    query: {
                        notice_id: row.notice_id
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            //编辑
            editNotice(row) {
                this.$router.push({
                    path: '/stateManage/AddNotice',
                    query: {
                        id: row.notice_id
                    }
                })
            },
            //删除
            delNotice(row) {
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let formData = new FormData();
                    formData.append('notice_id', row.notice_id);
                    noticeDel(formData).then((res) => {
                        this.$message.success(res.msg);
                        this.getNoticeList();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //分页
            noticeCurrentChange(val) {
                this.noticePages.currentPageNum = val;
                this.getNoticeList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .content {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 1%;
    }
</style>